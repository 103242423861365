export const ROUTES = Object.freeze({
  ALL: '*',
  ROOT: '/',
  APP_SETTINGS: {
    ROOT: '/app-settings',
  },
  ACTIVATE_CODE: {
    ROOT: '/activate-code',
  },
  PLAYER_BONUSES: {
    ROOT: '/player-bonuses',
  },
  GIVE_BONUS_MANAGEMENT: {
    ROOT: '/give-bonus',
  },
  CAMPAIGN_MONITOR_REPORT: {
    ROOT: '/monitor-report',
  },
  PLAYER_CODES: {
    ROOT: '/player-codes',
    DETAILS: '/player-codes/:id',
  },
  PLAYER_OFFERS: {
    ROOT: '/player-offers',
    DETAILS: '/player-offers/:id',
  },
  CODE_CAMPAIGNS: {
    ROOT: '/code-campaigns',
    EDIT: '/code-campaigns/:id/edit',
    CREATE: '/code-campaigns/create',
    CLONE: '/code-campaigns/:id/clone',
  },
  BONUS_MANAGEMENT: {
    ROOT: '/bonus-management',
    EDIT: '/bonus-management/:id/edit',
    CREATE: '/bonus-management/create',
    CLONE: '/bonus-management/:id/clone',
  },
  CAMPAIGN_ACTIVITY_REPORT: {
    ROOT: '/activity-report',
    OFFERS: '/activity-report/offers',
    BONUSES: '/activity-report/bonuses',
    OFFER_DETAILS: '/activity-report/offers/:id',
  },
  SCHEDULED_CAMPAIGNS: {
    ROOT: '/scheduled-campaigns',
    EDIT: '/scheduled-campaigns/:id/edit',
    CREATE: '/scheduled-campaigns/create',
    CLONE: '/scheduled-campaigns/:id/clone',
  },
  CAMPAIGN_MANAGEMENT: {
    ROOT: '/campaign-management',
    EDIT: '/campaign-management/:id/edit',
    CREATE: '/campaign-management/create',
    CLONE: '/campaign-management/:id/clone',
  },
  AUTOMATIC_CAMPAIGNS: {
    ROOT: '/automatic-campaigns',
    EDIT: '/automatic-campaigns/:id/edit',
    CREATE: '/automatic-campaigns/create',
    CLONE: '/automatic-campaigns/:id/clone',
  },
});
